<div class="container day-schedule-selector">  
  <div id="WeeklyScheduleDiv" class="row row-cols-1 row-cols-sm-1 row-cols-md-1">        
  
    <div class="row row-cols-12" style="margin-bottom: 10px !important;">
        <div class="col-1">
            <mat-button-toggle (change)="selectAllHours($event)" class="icon-display"><mat-icon class="icon-display">select_all</mat-icon></mat-button-toggle>
        </div>
        <div class="col-1">      
            <mat-button-toggle (change)="selectWorkingDays($event)" color="primary">WD</mat-button-toggle>
        </div>
        <div class="col-1">      
            <mat-button-toggle (change)="selectWeekEnd($event)" color="primary">WE</mat-button-toggle>
        </div>                          
        <div class="col-2">      
            <button class="mat-flat-button mat-flat-button-d" color="primary" (click)="loadSchedule()">Reload</button>          
        </div>
        <div class="col-2">      
            <button class="mat-flat-button mat-flat-button-d" color="primary" (click)="save()">Save</button>          
        </div>
    </div>

    <div class="div-mat-table">
    <mat-table [dataSource]="drScheduleSelector">
        
        <ng-container matColumnDef="hour">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let d" (click)="selectCell(d, 0)">{{d.hour}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mon">
            <mat-header-cell *matHeaderCellDef (click)="selectDay(1)">MON</mat-header-cell>
            <mat-cell [ngStyle]="{'background-color': d.mon ? cellc:''}" *matCellDef="let d;" (click)="selectCell(d, 1)"></mat-cell>
        </ng-container>

        <ng-container matColumnDef="tue">
            <mat-header-cell *matHeaderCellDef (click)="selectDay(2)">TUE</mat-header-cell>
            <mat-cell [ngStyle]="{'background-color': d.tue ? cellc:''}" *matCellDef="let d" (click)="selectCell(d, 2)"></mat-cell>
        </ng-container>

        <ng-container matColumnDef="wed">
            <mat-header-cell *matHeaderCellDef (click)="selectDay(3)">WED</mat-header-cell>
            <mat-cell [ngStyle]="{'background-color': d.wed ? cellc:''}" *matCellDef="let d" (click)="selectCell(d, 3)"></mat-cell>
        </ng-container>

        <ng-container matColumnDef="thu">
            <mat-header-cell *matHeaderCellDef (click)="selectDay(4)">THU</mat-header-cell>
            <mat-cell [ngStyle]="{'background-color': d.thu ? cellc:''}" *matCellDef="let d" (click)="selectCell(d, 4)"></mat-cell>
        </ng-container>

        <ng-container matColumnDef="fri">
            <mat-header-cell *matHeaderCellDef (click)="selectDay(5)">FRI</mat-header-cell>
            <mat-cell [ngStyle]="{'background-color': d.fri ? cellc:''}" *matCellDef="let d" (click)="selectCell(d, 5)"></mat-cell>
        </ng-container>

        <ng-container matColumnDef="sat">
            <mat-header-cell *matHeaderCellDef (click)="selectDay(6)">SAT</mat-header-cell>
            <mat-cell [ngStyle]="{'background-color': d.sat ? cellc:''}" *matCellDef="let d" (click)="selectCell(d, 6)"></mat-cell>
        </ng-container>

        <ng-container matColumnDef="sun">
            <mat-header-cell *matHeaderCellDef (click)="selectDay(7)">SUN</mat-header-cell>
            <mat-cell [ngStyle]="{'background-color': d.sun ? cellc:''}" *matCellDef="let d" (click)="selectCell(d, 7)"></mat-cell>
        </ng-container>

        <th>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        </th>
      
        <mat-row *matRowDef="let row; columns: displayedColumns" ></mat-row>      

    </mat-table>
    </div>

<div>
