<h3 mat-dialog-title>
<mat-icon>notes</mat-icon><b> Perimeter data display</b> </h3>
<mat-divider></mat-divider>        

<textarea readonly="true" style="font-size: 14px;">{{dataContent}}</textarea>

<div mat-dialog-actions align="end">
  <button mat-button color="usersbutton" (click)="onCloseClick()" cdkFocusInitial>Cancel</button>  
</div>


