<div class="container users-perimeter">
  <mat-grid-list cols="1" >
    <div class="row row-cols-12" style="margin-bottom: 10px !important;">
      <div class="col-1">
          <mat-button-toggle (change)="selectAllUsers($event)"><mat-icon class="icon-display">select_all</mat-icon></mat-button-toggle>
      </div>           
        <div class="col-2 users-perimeter-2">
          <button class="mat-flat-button mat-flat-button-d" color="primary" (click)="reload()">Reload</button> 
        </div>
        <div class="col-2 users-perimeter-2">
          <button class="mat-flat-button mat-flat-button-d" color="primary" (click)="save()">Save</button> 
        </div>              
    </div>
    <mat-grid-tile>
      <div class="div-grid-tile">              
          <mat-table class="lessons-table mat-elevation-z0" [dataSource]="usersData">
  
            <ng-container matColumnDef="user">
                <mat-cell *matCellDef="let u">
                  <mat-checkbox [(ngModel)]="u.selected">
                    <table>
                      <tr style="font-size: 16px;" > 
                        <td width="350px">{{u.full_name}}</td>
                        <td width="350px" *ngIf="u.last_position.value != ''">Last position detected: {{u.last_position.value}}, {{u.last_position.value_timestamp}} </td>                         
                      </tr>                     
                  </table>
                  </mat-checkbox>
              </mat-cell>
            </ng-container>
                
          <mat-row *matRowDef="let row; columns: displayedColumns" class="mat-row-users"></mat-row>
          </mat-table>            
  
      </div>  
    </mat-grid-tile>    
  </mat-grid-list>  
</div>



    
  

  


