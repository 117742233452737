import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { I18NHtmlParser } from '@angular/compiler';
import { I18nPluralPipe } from '@angular/common';

export interface Perimeter {
  id: number;
  name: string;
  selected: boolean;
  center: {
    name: string;
    coordinates: any;
  };
  coordinates: any;
  msg_inside: string;
  msg_outside: string;
  scheduling: any;
  init_zoom: number;
  start_timestamp: any;
  end_timestamp: any;
  user_ids: any;
  users_count: number;
}

export interface User {
  id: number;
  prefix_name: string;
  given_name: string;
  family_name: string;
  full_name: string;
  username: string;
  gender: string;
  birth_date: string;
  address: any;
  marital_status: string;
  language: string;
  email: string;
  height: number;
  education: string;
  religious_affiliation: string;
  selected: boolean;
  last_position: any;
}

export interface UserPerimeter {
  id: number;
  user_id: number;
  perimeter_id: number;
  scheduling: any;
  start_timestamp: any;
  end_timestamp: any;
}

// State machine's status
export enum Status {
  START,
  PERIMETER_SELECTED,
  ADDNEW,
  SAVENEW,
  EDITING,
  SAVEEDIT,
  DELETING,
}

@Injectable({
  providedIn: 'root',
})
export class GeofencingService {
  api_url: string = '';
  pilot: string = '';

  // api_url = "http://localhost:5016";              // API serving the core postgres db
  //api_url ="https://alss.eclexys.com:15016";

  auth_token: string;
  language: string;

  constructor(private httpClient: HttpClient) {
    this.language = 'EN';
    this.auth_token = '';
  }

  setToken(token: string) {
    this.auth_token = token;
  }

  setLanguage(language: string) {
    this.language = language;
  }

  getLanguage(): string {
    return this.language;
  }

  setApiUrl(api_url: string, pilot: string) {
    this.api_url = api_url;
    this.pilot = pilot;
  }

  getPilot() {
    return this.pilot;
  }

  /**
   * Returns all users
   * done
   */
  getAllUsers(filter = '', sortOrder = 'asc'): Observable<User[]> {
    return this.httpClient.get<User[]>(this.api_url + '/end_users', {
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.auth_token,
      },
    });
  }

  /**
   * Returns all perimeters
   * done
   */
  getAllPerimeters(): Observable<Perimeter[]> {
    return this.httpClient.get<Perimeter[]>(
      this.api_url + '/geofencing/perimeters',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.auth_token,
        },
      }
    );
  }

  /** done
   * This method save or update a perimeter
   */
  insertNewPerimeter(perimeter: Perimeter): Observable<Perimeter> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.post<Perimeter>(
      this.api_url + '/geofencing/perimeters',
      perimeter,
      {
        headers,
      }
    );
  }

  /** done
   * This method save or update a perimeter
   */
  updatePerimeter(perimeter: Perimeter, id: number): Observable<Perimeter> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.put<Perimeter>(
      this.api_url + '/geofencing/perimeters/' + id.toString(),
      perimeter,
      { headers }
    );
  }

  /**
   * This method update the scheduling for a perimeter
   * done
   */
  updateScheduling(perimeter_id: number, scheduling: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.put<any>(
      this.api_url + '/geofencing/perimeters/' + perimeter_id.toString(),
      { scheduling: scheduling },
      { headers }
    );
  }

  /**
   * This method update the users for a perimeter
   * done
   */
  updateUsersPerimeter(
    perimeter_id: number,
    user_ids: User[]
  ): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.put<any>(
      this.api_url + '/geofencing/perimeters/' + perimeter_id.toString(),
      { user_ids: user_ids },
      { headers }
    );
  }

  /**
   * This method delete a perimeter
   * done
   */
  deletePerimeter(perimeter_id: number): Observable<Perimeter> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.delete<Perimeter>(
      this.api_url + '/geofencing/perimeters/' + perimeter_id.toString(),
      { headers }
    );
  }

  /**
   * This method delete all perimeters having name  
  deletePerimetersByName(name: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token
    };
    this.httpClient
      .post<any>(
        this.api_url + '/mui/remove_perimeters_by_name',
        { name: name },
        { headers }
      )      
  }
  */

  /**
   * Returns last geoposition of all users
   * done
   */
  getLastGeoPositions(): Observable<any> {
    let queryParams = new HttpParams();
    return this.httpClient.get<any>(this.api_url + '/geofencing/outdoors', {
      params: queryParams,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.auth_token,
      },
    });
  }

  getAllRemoteTexts(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.api_url + '/texts', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.auth_token,
      },
    });
  }

  retrieveRemoteTranslation() {
    ['EN', 'IT', 'FR', 'DE', 'RO'].map(async (lang) => {});
    this.getAllRemoteTexts().subscribe((response) => {
      console.log(response);
      Object.keys(response).forEach((k: any) => {
        const translations = response[k];
        translations.forEach((x: any) => {
          //i18n.addResourceBundle(x["language"].toLowerCase(), "translation", { [`REMOTE.${x["name"].toUpperCase()}`]: x["description"] }, true, true);
        });
      });
    });
  }
}
