import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GeofencingService,
  User,
  Perimeter,
  Status,
} from 'src/app/services/geofencing.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersComponent } from '../users/users.component';
import { DatadisplayComponent } from '../datadisplay/datadisplay.component';
import { ConfirmationDialog } from '../helpers/confirmation-dialog';
import { Map } from 'ol';
import { GeomapService } from 'src/app/services/geomap.service';
//import { now } from "moment";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

export interface DialogData {
  users: User[];
  users_selected: User[];
}

// Component definition
@Component({
  selector: 'app-geofencing',
  templateUrl: './geofencing.component.html',
  styleUrls: ['./geofencing.component.css'],
})
export class GeofencingComponent implements OnInit, AfterViewInit, OnDestroy {
  minDate: Date;
  maxDate: Date;
  date = new Date();
  start: any;
  end: any;

  lastGeoPosition: string = '-';
  pilot: string;

  subscription: Subscription;

  defaultPerimeter: Perimeter;
  selectedPerimeter: Perimeter;

  currentStatus: Status = Status.START;

  //State variables
  listOfPerimetersEnabled: boolean = false;
  triNewEdit: string = 'N';
  mapEdit: boolean = true;
  deletePerimeterVisible: boolean = false;
  cancelPerimeterVisible: boolean = false;
  savePerimeterVisible: boolean = false;
  panelOpenState: boolean = true;
  panelHidden: boolean = true;
  panelUsersVisible: boolean = false;
  panelSchedulingVisible: boolean = false;

  drawnPerimeterCoords: Array<Array<string>> = [];
  refmap: Map;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  public usersDataSource = new MatTableDataSource<User>();
  public perimetersDataSource = new MatTableDataSource<Perimeter>();
  dialogRef?: MatDialogRef<ConfirmationDialog>;

  constructor(
    private router: Router,
    private geomapService: GeomapService,
    private geofencingService: GeofencingService,
    private dataService: DataService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    const currentYear = new Date().getFullYear();
    const day = new Date().getDate();
    const month = new Date().getMonth();
    this.minDate = new Date(currentYear, 0, 1);
    this.maxDate = new Date(currentYear, month, day);
    this.pilot = '';
    this.defaultPerimeter = {
      id: 0,
      name: 'Test',
      selected: false,
      center: {
        name: 'Lugano',
        coordinates: [8, 46],
      },
      coordinates: [],
      msg_inside: 'Entering',
      msg_outside: 'Exiting',
      scheduling: null,
      init_zoom: 11,
      start_timestamp: '',
      end_timestamp: '',
      user_ids: [],
      users_count: 0,
    };
    this.selectedPerimeter = this.defaultPerimeter;
    this.setStatus(Status.START);
    //Callback!
    this.subscription = this.dataService.data$.subscribe((data: boolean) => {
      if (data == true) {
        this.loadPerimeters();
        this.resetMap();
        this.geomapService.disableMapEdit();
        this.setStatus(Status.START);
        return;
      }
    });

    this.refmap = geomapService.returnMap();
  }

  // Set the status variables
  setStatus(status: any) {
    this.currentStatus = status;
    switch (status) {
      case Status.START:
        this.listOfPerimetersEnabled = true;
        this.triNewEdit = 'N';
        this.mapEdit = false;
        this.deletePerimeterVisible = false;
        this.cancelPerimeterVisible = false;
        this.savePerimeterVisible = false;
        this.panelOpenState = true;
        this.panelHidden = true;
        this.panelUsersVisible = false;
        this.panelSchedulingVisible = false;
        break;
      case Status.PERIMETER_SELECTED:
        this.listOfPerimetersEnabled = true;
        this.triNewEdit = 'E';
        this.mapEdit = true;
        this.deletePerimeterVisible = true;
        this.cancelPerimeterVisible = false;
        this.savePerimeterVisible = false;
        this.panelOpenState = true;
        this.panelHidden = true;
        this.panelUsersVisible = true;
        this.panelSchedulingVisible = true;
        break;
      case Status.ADDNEW:
        this.listOfPerimetersEnabled = false;
        this.triNewEdit = 'H';
        this.mapEdit = true;
        this.deletePerimeterVisible = false;
        this.cancelPerimeterVisible = true;
        this.savePerimeterVisible = true;
        this.panelOpenState = true;
        this.panelHidden = false;
        this.panelUsersVisible = false;
        this.panelSchedulingVisible = false;
        break;
      case Status.EDITING:
        this.listOfPerimetersEnabled = false;
        this.triNewEdit = 'H';
        this.mapEdit = true;
        this.deletePerimeterVisible = false;
        this.cancelPerimeterVisible = true;
        this.savePerimeterVisible = true;
        this.panelOpenState = true;
        this.panelHidden = false;
        this.panelUsersVisible = true;
        this.panelSchedulingVisible = true;
        break;
      case Status.DELETING:
        this.listOfPerimetersEnabled = false;
        this.triNewEdit = 'H';
        this.mapEdit = true;
        this.deletePerimeterVisible = false;
        this.cancelPerimeterVisible = false;
        this.savePerimeterVisible = false;
        this.panelOpenState = true;
        this.panelHidden = true;
        this.panelUsersVisible = false;
        this.panelSchedulingVisible = false;
        break;
    }
  }

  // field validators
  registerPerimeter = new FormGroup({
    perimeter_name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(40),
    ]),
    /*    center_name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(40)]),
    center_longitude: new FormControl('', [Validators.required, Validators.pattern("^[+-]?((\\d+(\\.\\d*)?)|(\\.\\d+))$"),
              Validators.min(-180), Validators.max(180)]),
    center_latitude: new FormControl('', [Validators.required, Validators.pattern("^[+-]?((\\d+(\\.\\d*)?)|(\\.\\d+))$"),
              Validators.min(-90), Validators.max(90)]), */
    init_zoom: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(0),
      Validators.max(23),
    ]),
    message_IN: new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]),
    message_OUT: new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]),
  });

  // ***************

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.refmap.setTarget('map-container');
    this.geomapService.setMouseTarget('mouse-position');
    this.geomapService.setTooltip(document.getElementById('tooltip'));
    this.geomapService.setMap(this.refmap);
  }

  ngOnInit(): void {
    this.pilot = this.geofencingService.getPilot(); //get te pilot number, to be displayed with the title
    // let old_status = this.currentStatus;
    this.geomapService.perimeterSelectedObs.subscribe((ps) => {
      if (ps.id != 0) {
        this.selectedPerimeter = ps;
        this.dataService.sendSelectedPerimeter(
          this.perimetersDataSource.data.find((p) => p.id === ps.id)
        );
        this.setStatus(Status.PERIMETER_SELECTED);
      } else {
        this.selectedPerimeter = ps;
        this.selectedPerimeter.selected = false;
        this.setStatus(Status.START);
      }
    });

    this.start = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.date.getDate() - 1
    ).toISOString();

    this.end = this.date.toISOString();

    this.getAllPerimeters();
    this.currentStatus = Status.START;
  }

  /**
   * Returns all perimeters
   */
  getAllPerimeters() {
    this.loadPerimeters();
  }

  resetMap() {
    this.geomapService.removeAllPerimetersFromMap();
    this.geomapService.resetPopup();
    this.geomapService.resetMap();
  }

  loadPerimeters() {
    this.perimetersDataSource.data = [];
    this.selectedPerimeter = this.defaultPerimeter;
    this.resetMap();
    this.geofencingService.getAllPerimeters().subscribe((res) => {
      res.forEach((p) => {
        for (let i = 0; i < p.coordinates.length; i++) {
          p.coordinates[i][0] = p.coordinates[i][0].toString();
          p.coordinates[i][1] = p.coordinates[i][1].toString();
        }
        //p.coordinates =  p.coordinates;
        p.users_count = p.user_ids.length;
        if (p.start_timestamp == null)
          p.start_timestamp = '2000-01-01 00:01:00+00';
        else
          p.start_timestamp = new Date(
            parseInt(p.start_timestamp) * 1000
          ).toUTCString();
        if (p.end_timestamp == null) p.end_timestamp = '3000-12-31 23:59:00+00';
        else
          p.end_timestamp = new Date(
            parseInt(p.end_timestamp) * 1000
          ).toUTCString();
        this.perimetersDataSource.data.push(p);
      });
      if (this.perimetersDataSource.data.length > 0)
        this.drawPerimeters(this.perimetersDataSource.data);
    });
  }

  drawPerimeters(perimeters: Perimeter[]) {
    perimeters.forEach((p) => {
      let coords: [] = p.coordinates;
      let vcoords: Array<Array<number>> = [];
      coords.forEach((c) => {
        vcoords.push([parseFloat(c[0]), parseFloat(c[1])]);
      });
      this.geomapService.drawPerimeterOnMap([vcoords], p);
    });

    this.geomapService.rescaleView(perimeters);
  }

  closePerimeterEditor(s: string) {
    this.loadPerimeters();
    this.resetMap();
    this.geomapService.disableMapEdit();
    if (this.currentStatus == Status.ADDNEW) this.setStatus(Status.START);
    //this.setStatus(Status.PERIMETER_SELECTED);
    else this.setStatus(Status.START);
  }

  editPerimeter() {
    if (this.selectedPerimeter.selected) this.updatePerimeter();
    else this.newPerimeter();
  }

  newPerimeter() {
    this.setStatus(Status.ADDNEW);
    this.selectedPerimeter = this.defaultPerimeter;
    this.drawnPerimeterCoords = [];
    this.geomapService.enableMapEdit();
  }

  updatePerimeter() {
    this.setStatus(Status.EDITING);
    this.drawnPerimeterCoords = this.selectedPerimeter.coordinates;
    this.geomapService.enableMapUpdate();
  }

  deletePerimeter() {
    this.setStatus(Status.DELETING);
    this.openRemovePerimeterConfirmationDialog();
  }

  preparePerimeterData() {
    this.selectedPerimeter.center.name = 'Europe';

    this.selectedPerimeter.center.coordinates[0] = 8.9;
    this.selectedPerimeter.center.coordinates[1] = 46.01;

    this.selectedPerimeter.coordinates = [];
    for (let i = 0; i < this.drawnPerimeterCoords.length; i++) {
      this.selectedPerimeter.coordinates.push([
        parseFloat(this.drawnPerimeterCoords[i][0]),
        parseFloat(this.drawnPerimeterCoords[i][1]),
      ]);
    }

    let d = new Date();
    d.setDate(d.getDate() - 1); // yesterday
    if (this.selectedPerimeter.start_timestamp == null) {
      this.selectedPerimeter.start_timestamp = d.toISOString();
      this.selectedPerimeter.start_timestamp =
        Date.parse(this.selectedPerimeter.start_timestamp) / 1000;
    } else if (this.selectedPerimeter.start_timestamp == '') {
      this.selectedPerimeter.start_timestamp = 1577833200;
    } else {
      this.selectedPerimeter.start_timestamp =
        Date.parse(this.selectedPerimeter.start_timestamp) / 1000;
    }

    if (this.selectedPerimeter.end_timestamp == null)
      this.selectedPerimeter.end_timestamp = '3000-12-31 23:59:59';
    else if (this.selectedPerimeter.end_timestamp == '')
      this.selectedPerimeter.end_timestamp = '3000-12-31 23:59:59';
    this.selectedPerimeter.end_timestamp =
      Date.parse(this.selectedPerimeter.end_timestamp) / 1000;
  }

  savePerimeter() {
    let p = this.geomapService.getDrawnPerimeterCoordinates();
    if (p.length > 0) this.drawnPerimeterCoords = p;
    /*     if (this.drawnPerimeterCoords.hasOwnProperty('coordinates')) {
      let d: any = this.drawnPerimeterCoords;
      this.drawnPerimeterCoords = d.coordinates;
    } */
    if (this.drawnPerimeterCoords.length == 0) {
      this.setStatus(Status.START);
      return;
    }
    this.preparePerimeterData();
    /* if new perimeter, add */
    if (this.currentStatus == Status.ADDNEW) {
      // this.selectedPerimeter.id == 0
      let storePerim: any = this.selectedPerimeter;
      storePerim.scheduling = {};
      delete storePerim.id;
      delete storePerim.selected;
      delete storePerim.users_count;
      this.geofencingService.insertNewPerimeter(storePerim).subscribe((d) => {
        this.closePerimeterEditor('');
        console.log('perimeter saved:');
      });
    } else {
      /* otherwise update the perimeter */
      let storePerim: any = this.selectedPerimeter;
      let id: number = storePerim.id;
      delete storePerim.id;
      delete storePerim.selected;
      delete storePerim.users_count;
      this.geofencingService.updatePerimeter(storePerim, id).subscribe((d) => {
        this.closePerimeterEditor('');
        console.log('perimeter saved:');
      });
    }
  }

  onPerimeterChange(p: any, event: any) {
    if (event.isUserInput) {
      //this.selectedPerimeter = p;
      //this.geomapService.selectPerimeter(p['name']);
      this.geomapService.rescaleViewPerimeter(p.coordinates);
      //this.selectedPerimeter.selected = true;
    }
  }

  openRemovePerimeterConfirmationDialog() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete this perimeter?';
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // do confirmation actions
        this.geofencingService
          .deletePerimeter(this.selectedPerimeter.id)
          .subscribe((d) => {
            this.geomapService.removePerimeterFromMap(
              this.selectedPerimeter.id
            );
            this.selectedPerimeter.selected = false;
            console.log('perimeter removed:');
            console.log(JSON.stringify(this.selectedPerimeter));
            this.perimetersDataSource.data = [];
            this.geofencingService.getAllPerimeters().subscribe((res) => {
              res.forEach((p) => {
                this.perimetersDataSource.data.push(p);
              });
              this.geomapService.resetMap();
              if (this.perimetersDataSource.data.length > 0)
                this.drawPerimeters(this.perimetersDataSource.data);
              this.setStatus(Status.START);
            });
          });
      }
    });
  }

  // open Datadisplay form
  openDataDisplayDialog(): void {
    const dialogRef = this.dialog.open(DatadisplayComponent, {
      height: '90%',
      width: '80%',
      data: { perimeter: JSON.stringify(this.selectedPerimeter, null, '\t') },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //
    });
  }
}
