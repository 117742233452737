import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private data = new Subject<any>();
  data$ = this.data.asObservable();

  sendSelectedPerimeter(data: any) {
    this.data.next(data);
  }

  triggerReloadPerimeters(data: any) {
    this.data.next(data);
  }
}