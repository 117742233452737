import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { scheduled, Subscription } from 'rxjs';
import {
  GeofencingService,
  Perimeter,
} from 'src/app/services/geofencing.service';
import { DataService } from 'src/app/services/data.service';

interface IDay {
  hour: string;
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
}

@Component({
  selector: 'weekly-schedule',
  templateUrl: './day-schedule-selector.component.html',
  styleUrls: ['./day-schedule-selector.component.css'],
})
export class WeeklyScheduleComponent implements OnInit, OnDestroy {
  daySched: any = {};
  drScheduleSelector = new MatTableDataSource<IDay>();
  displayedColumns: any[] = [
    'hour',
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
  ];
  selectedPerimeter: Perimeter = {
    id: 0,
    name: '',
    selected: false,
    center: {
      name: '',
      coordinates: undefined,
    },
    coordinates: undefined,
    msg_inside: '',
    msg_outside: '',
    scheduling: undefined,
    init_zoom: 0,
    start_timestamp: '',
    end_timestamp: '',
    user_ids: [],
    users_count: 0,
  };
  subscription: Subscription;

  readonly cellc: string = 'lightgreen'; //background color of a selected cell.
  constructor(
    private geofencingService: GeofencingService,
    private dataService: DataService
  ) {
    this.subscription = this.dataService.data$.subscribe((data: Perimeter) => {
      if (data != undefined) this.selectedPerimeter = data;
      this.loadSchedule();
    });
  }

  ngOnInit() {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 2; j++) {
        this.drScheduleSelector.data.push({
          hour:
            j == 0
              ? i < 10
                ? '0' + i.toString() + ':00'
                : i.toString() + ':00'
              : i < 10
              ? '0' + i.toString() + ':30'
              : i.toString() + ':30',
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false,
        });
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectCell(d: IDay, day: number): void {
    switch (day) {
      case 0:
        d.mon = !d.mon;
        d.tue = !d.tue;
        d.wed = !d.wed;
        d.thu = !d.thu;
        d.fri = !d.fri;
        d.sat = !d.sat;
        d.sun = !d.sun;
        break;
      case 1:
        d.mon = !d.mon;
        break;
      case 2:
        d.tue = !d.tue;
        break;
      case 3:
        d.wed = !d.wed;
        break;
      case 4:
        d.thu = !d.thu;
        break;
      case 5:
        d.fri = !d.fri;
        break;
      case 6:
        d.sat = !d.sat;
        break;
      case 7:
        d.sun = !d.sun;
        break;
    }
  }

  selectDay(day: number): void {
    this.drScheduleSelector.data.forEach((d) => {
      switch (day) {
        case 1:
          d.mon = !d.mon;
          break;
        case 2:
          d.tue = !d.tue;
          break;
        case 3:
          d.wed = !d.wed;
          break;
        case 4:
          d.thu = !d.thu;
          break;
        case 5:
          d.fri = !d.fri;
          break;
        case 6:
          d.sat = !d.sat;
          break;
        case 7:
          d.sun = !d.sun;
          break;
      }
    });
  }

  selectAllHours(event: any): void {
    this.drScheduleSelector.data.forEach((d) => {
      if (event.source.checked) {
        d.mon = d.tue = d.wed = d.thu = d.fri = d.sat = d.sun = true;
      } else {
        d.mon = d.tue = d.wed = d.thu = d.fri = d.sat = d.sun = false;
      }
    });
  }

  selectWorkingDays(event: any): void {
    this.drScheduleSelector.data.forEach((d) => {
      if (event.source.checked) {
        d.mon = d.tue = d.wed = d.thu = d.fri = true;
      } else {
        d.mon = d.tue = d.wed = d.thu = d.fri = false;
      }
    });
  }

  selectWeekEnd(event: any): void {
    this.drScheduleSelector.data.forEach((d) => {
      if (event.source.checked) {
        d.sat = d.sun = true;
      } else {
        d.sat = d.sun = false;
      }
    });
  }

  save(): void {
    this.prepareSchedule();
    this.geofencingService
      .updateScheduling(this.selectedPerimeter.id, this.daySched)
      .subscribe((d) => {
        this.dataService.triggerReloadPerimeters(true);
      });
  }

  loadSchedule(): void {
    if (this.selectedPerimeter.scheduling != null) {
      this.daySched = this.selectedPerimeter.scheduling;
      this.readSchedule();
    }
  }

  /* Read schedules from content of this.drScheduleSelector.data, and put it in this.daySched
    . this.daySched has the form
      {"1":[],"2":[["02:30","03:00"]],"3":[],"4":[["03:30","03:30"],["04:30","04:30"]],"5":[],"6":[],"7":[]}
    . this.drScheduleSelector.data has the form:
      [{"hour":"00:00","mon":false,"tue":false,"wed":false,"thu":false,"fri":false,"sat":false,"sun":false},
      {"hour":"00:30","mon":false,"tue":false,"wed":false,"thu":false,"fri":false,"sat":false,"sun":false},...]
   */
  private prepareSchedule(): void {
    this.daySched = {};
    let s: any;
    s = {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
    };
    let t: any;
    t = {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
    };

    this.drScheduleSelector.data.forEach((d) => {
      s['1'].push(d.mon ? d.hour : '');
      s['2'].push(d.tue ? d.hour : '');
      s['3'].push(d.wed ? d.hour : '');
      s['4'].push(d.thu ? d.hour : '');
      s['5'].push(d.fri ? d.hour : '');
      s['6'].push(d.sat ? d.hour : '');
      s['7'].push(d.sun ? d.hour : '');
    });

    let start: string = '';
    let z: string = '';
    let n: number = 0;
    for (let k = 1; k <= 7; k++) {
      z = k.toString();
      n = s[z].length - 1;
      for (let i = 0; i <= n; i++) {
        if (s[z][i] != '') {
          if (start == '') {
            start = s[z][i];
          }
        } else {
          if (start != '') {
            t[z].push([start, s[z][i - 1]]);
            start = '';
          }
        }
      }
      if (start != '' && s[z][n] != '') {
        t[z].push([start, s[z][n]]);
        start = '';
      }
    }

    for (let k = 1; k <= 7; k++) {
      z = k.toString();
      if (t[z].length == 0) delete t[z];
    }

    this.daySched = t;
  }

  /* Read schedules from content of this.daySched, and put it in this.drScheduleSelector.data
    . this.daySched has the form
      {"1":[],"2":[["02:30","03:00"]],"3":[],"4":[["03:30","03:30"],["04:30","04:30"]],"5":[],"6":[],"7":[]}
    . this.drScheduleSelector.data has the form:
      [{"hour":"00:00","mon":false,"tue":false,"wed":false,"thu":false,"fri":false,"sat":false,"sun":false},
      {"hour":"00:30","mon":false,"tue":false,"wed":false,"thu":false,"fri":false,"sat":false,"sun":false},...]
  */
  private readSchedule(): void {
    this.drScheduleSelector.data.forEach((d) => {
      d.mon = d.tue = d.wed = d.thu = d.fri = d.sat = d.sun = false;
    });
    for (let k = 1; k <= 7; k++) {
      let d = this.daySched[k.toString()];
      if (d != undefined) {
        for (let i = 0; i < d.length; i++) {
          let n = this.drScheduleSelector.data.findIndex((obj: any) => {
            if (obj.hour == d[i][0]) {
              return true;
            }
            return false;
          });
          let m = n;
          if (d[i][0] != d[i][1]) {
            for (m = n + 1; m < this.drScheduleSelector.data.length; m++) {
              if (this.drScheduleSelector.data[m].hour == d[i][1]) break;
            }
          }
          for (let i = n; i <= m; i++) {
            switch (k) {
              case 1:
                this.drScheduleSelector.data[i].mon = true;
                break;
              case 2:
                this.drScheduleSelector.data[i].tue = true;
                break;
              case 3:
                this.drScheduleSelector.data[i].wed = true;
                break;
              case 4:
                this.drScheduleSelector.data[i].thu = true;
                break;
              case 5:
                this.drScheduleSelector.data[i].fri = true;
                break;
              case 6:
                this.drScheduleSelector.data[i].sat = true;
                break;
              case 7:
                this.drScheduleSelector.data[i].sun = true;
                break;
            }
          }
        }
      }
    }
  }
}
