import { Input, Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GeofencingService } from '../../services/geofencing.service';
import { AuthService } from '../../services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

const RE_URL: string = 'http://localhost:35452/';

// here goes the table of credentials  ***************

let authTable = [
  {
    user: 'luca',
    pass: 'Piga.5001',
    appl: ['Geo', 'MRE'],
    pilot: 'localhost',
    api_url: 'http://localhost:5016',
  },
  {
    user: 'exys',
    pass: 'Riva.2664',
    appl: ['Geo', 'MRE'],
    pilot: 'pilot 500',
    api_url: 'https://app.eliot-care.ch/api/v1',
    // api_url: 'http://172.24.24.173:5016/api/v1',
  },
];

// ****************************************************

@Component({
  selector: 'app-login',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent {
  loading: boolean = false;
  submitted: boolean = false;

  btnName: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private geofencingService: GeofencingService,
    private authService: AuthService
  ) {}

  form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  setBtnName(name: string) {
    this.btnName = name;
  }

  ngOnInit(): void {
    // Listen for messages from the parent
    window.addEventListener('message', (event) => {
      // Check the origin of the message for security purposes
      console.log(event.origin);
      if (event.origin == 'http://localhost:35551') {
        // Update the received message
        /* this.form.value.username = event.data.username;
        this.form.value.password = event.data.password; */
        this.geofencingService.setLanguage('IT');
        this.geofencingService.setToken(
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcyODU0MzcyNywianRpIjoiODNmOTJhMWEtOWE5NC00OGI1LTg0ZGMtNGJlOWU1YWFjMDQzIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6eyJ1aWQiOjQsInVzZXJuYW1lIjoiZXVnZW5pb19jYXZhbGxvdHRpIiwicm9sZSI6WyJDQVJFUiJdfSwibmJmIjoxNzI4NTQzNzI3LCJleHAiOjE3Mjg2MzAxMjd9.8eFQqMh2SlC3Fa6-D1eeqAzga-oehKYADFPH0KtcsG4'
        );
        this.authentic('Geo');
      } else if (event.origin == 'https://app.eliot-care.ch') {
        this.geofencingService.setLanguage(event.data.language);
        this.geofencingService.setToken(event.data.token);
        this.authentic('Geo');
      } else return;
    });
  }

  validateLogin(appl: string) {
    this.form.value.username = 'exys';
    this.form.value.password = 'Riva.2664';
    let outcome: any = {
      valid: false,
      pilot: '',
      api_url: '',
    };
    authTable.forEach((entry) => {
      if (entry.appl.includes(appl))
        if (entry.user === this.form.value.username)
          if (entry.pass === this.form.value.password)
            outcome = {
              valid: true,
              api_url: entry.api_url,
              pilot: entry.pilot,
            };
    });
    return outcome;
  }

  authentic(event: string) {
    let validation = this.validateLogin(event);
    if (validation.valid) {
      this.authService.login();
      let url: string = '';
      if (event === 'Geo') url = '/geofencing';
      else return false;
      this.router
        .navigate([url])
        .then((x) => {
          console.log('Logged.');
          console.log(validation.api_url);
          if (validation.api_url != null) {
            this.geofencingService.setApiUrl(
              validation.api_url,
              validation.pilot
            );
            return true;
          } else return false;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    }
    this.error = 'unable to authenticate';
    return false;
  }

  submit(event: string) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      this.authentic(event);
    }
  }

  @Input() error: string | null | undefined;

  @Output() submitEM = new EventEmitter();
}
