import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs'
import { Injectable } from "@angular/core";

export interface Perimeter {
  id: number;
  name: string;
  user_id: number;
  center: {
    name: string;
    coordinates: any;
  };
  coordinates: any;
  msg_inside: string;
  msg_outside: string;
  init_zoom: number;
  start_timestamp: string;
  value_timestamp: string;  
}

export interface User {
  birthdate: string;
  fhir_identifier: string;
  gender: string;
  height: number;
  id: number;
  name: string;
  timestamp: string;
  tmz: string;
  username: string;
  selected: boolean;
  perim_count: number;
}


@Injectable({
  providedIn: "root",
})
export class GeofencingService {

  api_url: string = '';
  pilot: string = '';

  // api_url = "http://localhost:5016";              // API serving the core postgres db
  //api_url ="https://alss.eclexys.com:15016";
  
  auth_token: string;     
  
  constructor(private httpClient: HttpClient) {
    this.auth_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE5ODU3NTk3Mzd9.HUpUHZQhgfrGge7vUBBbGkNlUYWbpk5RyAUtJih3ZJY";
  }
  
  setApiUrl(api_url: string, pilot: string) {
    this.api_url = api_url;
    this.pilot = pilot;
  }

  getPilot() {    
    return this.pilot;
  }

  /**
   * Returns all users
   */
  getAllUsers(filter = '', sortOrder = 'asc'): Observable<User[]> {
  return this.httpClient.get<User[]>(this.api_url + "/hhmp/get_all_patients", {
      params: new HttpParams()            
          .set('filter', filter)
          .set('sortOrder', sortOrder),
      headers: { "Content-Type": "application/json", "x-access-tokens": this.auth_token}
    });
  }

  /**
   * Returns all perimeters of a user
   */
  getAllPerimetersOfUser (user_id: number): Observable<Perimeter[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("patient_id", user_id);      
    return this.httpClient.get<Perimeter[]>(this.api_url + "/mui/get_all_perimeters_user", {
      params: queryParams,        
      headers: { "Content-Type": "application/json", "x-access-tokens": this.auth_token}
    });
  }

  /**
   * This method returns save or update a perimeter
   */    
    insertNewPerimeter(perimeter: Perimeter) {      
      const headers = { "Content-Type": "application/json", "x-access-tokens": this.auth_token};
      this.httpClient.post<Perimeter>(this.api_url + "/mui/insert_new_perimeter", {'payload': perimeter}, {headers})
              .subscribe(d => console.log(d));
    }
  
  /**
   * This method delete a perimeter
   */    
    deletePerimeter(perimeter_id: number) {
      const headers = { "Content-Type": "application/json", "x-access-tokens": this.auth_token};
      this.httpClient.post<Perimeter>(this.api_url + "/mui/remove_perimeter", {'perimeter_id': perimeter_id}, {headers})
              .subscribe(d => console.log(d));
    }


   /**
   * This method delete all perimeters having name
   */    
    deletePerimetersByName(name: string) {
      const headers = { "Content-Type": "application/json", "x-access-tokens": this.auth_token};
      this.httpClient.post<Perimeter>(this.api_url + "/mui/remove_perimeters_by_name", {'name': name}, {headers})
              .subscribe(d => console.log(d));
    }

  /**
   * Returns all perimeters of a user
   */
  countPerimetersByUser (user_id: number): Observable<number> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("patient_id", user_id);      
    return this.httpClient.get<number>(this.api_url + "/mui/count_perimeters_by_user", {
      params: queryParams,        
      headers: { "Content-Type": "application/json", "x-access-tokens": this.auth_token}
    });
  }

 /**
   * Returns all perimeters of a user
   */
 getLastGeoPosition (user_id: number): Observable<any> {
  let queryParams = new HttpParams();  
  queryParams = queryParams.append("user_id", user_id);   
  return this.httpClient.get<any>(this.api_url + "/mui/get_last_geo_position", {
    params: queryParams,        
    headers: { "Content-Type": "application/json", "x-access-tokens": this.auth_token}
  });
}

}
