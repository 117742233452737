import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GeofencingService,
  User,
  Perimeter,
} from 'src/app/services/geofencing.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersComponent } from '../users/users.component';
import { DatadisplayComponent } from '../datadisplay/datadisplay.component';
import { ConfirmationDialog } from '../helpers/confirmation-dialog';
import { Map } from 'ol';
import { GeomapService, IPerimeter } from 'src/app/services/geomap.service';
//import { now } from "moment";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { DatePipe } from '@angular/common';

export interface DialogData {
  users: User[];
  users_selected: User[];
}

enum Status {
  START,
  USERSELECTED,
  ADDNEW,
  ADDNEW_CLOSE,
  PERIMETER_SELECTED,
  UPDATE,
  UPDATE_CLOSE,
}

// Component definition
@Component({
  selector: 'app-geofencing',
  templateUrl: './geofencing.component.html',
  styleUrls: ['./geofencing.component.css'],
})
export class GeofencingComponent implements OnInit, AfterViewInit {
  minDate: Date;
  maxDate: Date;
  date = new Date();
  start: any;
  end: any;
  lastGeoPosition: string = '-';
  pilot: string;
  selectedUser!: User;
  userName: string;
  defaultPerimeter: Perimeter;
  selectedPerimeter: Perimeter;
  flagStatus: Status;
  flagUpdate: boolean = false;
  editPerimeterDisabled: boolean = true;
  cancelPerimeterDisabled: boolean = false;
  savePerimeterDisabled: boolean = false;
  extendPerimeterToAllDisabled: boolean = false;
  perimeterSelected: IPerimeter;
  panelOpenState: boolean = true;
  panelHidden: boolean = true;
  activeChecked: boolean = true;
  name: string;
  drawnPerimeterCoords: Array<Array<string>> = [];
  refmap: Map;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  selectedUsersData: User[] = [];

  public usersDataSource = new MatTableDataSource<User>();
  public perimetersDataSource = new MatTableDataSource<Perimeter>();
  dialogRef?: MatDialogRef<ConfirmationDialog>;

  constructor(
    private router: Router,
    private geomapService: GeomapService,
    private geofencingService: GeofencingService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    const currentYear = new Date().getFullYear();
    const day = new Date().getDate();
    const month = new Date().getMonth();
    this.minDate = new Date(currentYear, 0, 1);
    this.maxDate = new Date(currentYear, month, day);
    this.userName = '';
    this.pilot = '';
    this.defaultPerimeter = {
      id: 0,
      name: 'Test',
      user_id: 1,
      center: {
        name: 'Lugano',
        coordinates: [8, 46],
      },
      coordinates: [],
      msg_inside: 'Entering',
      msg_outside: 'Exiting',
      init_zoom: 11,
      start_timestamp: '',
      value_timestamp: '',
    };
    this.selectedPerimeter = this.defaultPerimeter;
    this.perimeterSelected = {
      id: 0,
      user_id: 0,
      name: '',
      selected: false,
      start_timestamp: '',
      value_timestamp: '',
    };
    this.name = '';
    this.flagStatus = Status.START;
    this.setStatus();
    this.refmap = geomapService.returnMap();
  }

  setStatus() {
    switch (this.flagStatus) {
      case Status.START:
        this.editPerimeterDisabled = true;
        this.flagUpdate = false;
        this.panelOpenState = true;
        this.panelHidden = true;
        this.activeChecked = false;
        break;
      case Status.USERSELECTED:
      case Status.ADDNEW_CLOSE:
        this.editPerimeterDisabled = false;
        this.flagUpdate = false;
        this.panelOpenState = true;
        this.panelHidden = true;
        this.activeChecked = false;
        break;
      case Status.ADDNEW:
        this.editPerimeterDisabled = true;
        this.flagUpdate = false;
        this.panelOpenState = true;
        this.panelHidden = false;
        this.activeChecked = false;
        break;
      case Status.UPDATE:
        this.editPerimeterDisabled = false;
        this.panelHidden = false;
        this.flagUpdate = true;
        this.activeChecked = true;
        break;
    }
  }

  // field validators

  registerPerimeter = new FormGroup({
    perimeter_name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(40),
    ]),
    /*    center_name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(40)]),
    center_longitude: new FormControl('', [Validators.required, Validators.pattern("^[+-]?((\\d+(\\.\\d*)?)|(\\.\\d+))$"),
              Validators.min(-180), Validators.max(180)]),
    center_latitude: new FormControl('', [Validators.required, Validators.pattern("^[+-]?((\\d+(\\.\\d*)?)|(\\.\\d+))$"),
              Validators.min(-90), Validators.max(90)]), */
    init_zoom: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(0),
      Validators.max(23),
    ]),
    message_IN: new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]),
    message_OUT: new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]),
  });

  // ***************

  ngAfterViewInit(): void {
    this.refmap.setTarget('map-container');
    this.geomapService.setMouseTarget('mouse-position');
    this.geomapService.setTooltip(document.getElementById('tooltip'));
    this.geomapService.setMap(this.refmap);

/*     $("#weekly-schedule").dayScheduleSelector({      
     
    }); 
    */

  }

  ngOnInit(): void {
    this.pilot = this.geofencingService.getPilot(); //get te pilot number, to be displayed with the title
    this.geomapService.perimeterSelectedObs.subscribe((perimeterSelected) => {
      if (perimeterSelected) {
        this.perimeterSelected = perimeterSelected;
        this.setDefaultPerimeter();
      } else {
        this.perimeterSelected = perimeterSelected;
        this.perimeterSelected.selected = false;
      }
    });

    this.start = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.date.getDate() - 1
    ).toISOString();

    this.end = this.date.toISOString();

    let allRules: any = {};
    let ar: any = {};

    this.getAllUsers();
  }

  setDefaultPerimeter() {
    let perim = this.perimetersDataSource.data.find((p) => {
      return p.id === this.perimeterSelected.id;
    });
    if (perim) {
      this.selectedPerimeter = perim;
    }
  }

  /**
   * Returns all fhir users
   */
  getAllUsers() {
    this.geofencingService.getAllUsers().subscribe((res) => {
      res.forEach((u) => {
        if (u['fhir_identifier'] != null)
          this.geofencingService
            .countPerimetersByUser(u['id'])
            .subscribe((count) => {
              u['perim_count'] = count;
              this.usersDataSource.data.push(u);
            });
      });
    });
  }

  /**
   * Returns all perimeters of a user
   */
  getAllPerimetersOfUser(user_id: number) {
    this.perimetersDataSource.data = [];
    this.geomapService.removePerimetersOfUserFromMap(user_id);
    this.selectedPerimeter = this.defaultPerimeter;
    this.perimeterSelected = {
      id: 0,
      user_id: 0,
      name: '',
      selected: false,
      start_timestamp: '',
      value_timestamp: '',
    };
    this.geomapService.resetPopup();
    this.geomapService.resetMap();
    this.geofencingService.getAllPerimetersOfUser(user_id).subscribe((res) => {
      res.forEach((p) => {
        this.perimetersDataSource.data.push(p);
      });
      if (this.perimetersDataSource.data.length > 0)
        this.drawPerimeters(this.perimetersDataSource.data);
      else this.geomapService.resetMap();
    });
  }

  drawPerimeters(perimeters: Perimeter[]) {
    perimeters.forEach((p) => {
      let coords: [] = p.coordinates.coordinates;
      let vcoords: Array<Array<number>> = [];
      coords.forEach((c) => {
        vcoords.push([parseFloat(c[0]), parseFloat(c[1])]);
      });
      this.geomapService.drawPerimeterOnMap(
        [vcoords],
        p.name,
        p.id,
        p.user_id,
        p.start_timestamp,
        p.value_timestamp
      );
    });

    this.geomapService.rescaleView(perimeters);
  }

  closePerimeterEditor(): void {
    this.flagStatus = Status.ADDNEW_CLOSE;
    this.setStatus();
    this.geomapService.removeNewPerimeterFromMap();
    this.geomapService.disableMapEdit();
  }

  editPerimeter() {
    if (this.perimeterSelected.selected) this.updatePerimeter();
    else this.newPerimeter();
  }

  newPerimeter() {
    this.flagStatus = Status.ADDNEW;
    this.setStatus();
    this.selectedPerimeter = this.defaultPerimeter;
    this.drawnPerimeterCoords = [];
    this.geomapService.enableMapEdit();
  }

  updatePerimeter() {
    this.flagStatus = Status.UPDATE;
    this.setStatus();
    this.drawnPerimeterCoords = this.selectedPerimeter.coordinates;
    console.log(this.drawnPerimeterCoords);   
    this.geomapService.enableMapUpdate();
  }

  deletePerimeter() {
    this.openRemovePerimeterConfirmationDialog();
  }

  deletePerimeterForAll() {
    this.openRemovePerimeterForAllUsersConfirmationDialog();
  }

  preparePerimeterData() {
    this.closePerimeterEditor();

    this.selectedPerimeter.center.name = 'Europe';

    //stringify center's coordinates before store them (so we haven't to change the parser)
    /* this.selectedPerimeter.center.coordinates[0] = this.selectedPerimeter.center.coordinates[0].toString();
    this.selectedPerimeter.center.coordinates[1] = this.selectedPerimeter.center.coordinates[1].toString();     */

    this.selectedPerimeter.center.coordinates[0] = '8.90';
    this.selectedPerimeter.center.coordinates[1] = '46.01';

    this.selectedPerimeter.coordinates = this.drawnPerimeterCoords;

    let d = new Date();
    d.setDate(d.getDate() - 1); // yesterday
    if (this.selectedPerimeter.start_timestamp == null)
      this.selectedPerimeter.start_timestamp = d.toISOString();
    else if (this.selectedPerimeter.start_timestamp == '')
      this.selectedPerimeter.start_timestamp = d.toISOString();

    if (this.selectedPerimeter.value_timestamp == null)
      this.selectedPerimeter.value_timestamp = '3000-12-31';
    else if (this.selectedPerimeter.value_timestamp == '')
      this.selectedPerimeter.value_timestamp = '3000-12-31';
  }

  savePerimeter() {
    let p = this.geomapService.getDrawnPerimeterCoordinates();
    if (p.length > 0) this.drawnPerimeterCoords = p;
    if (this.drawnPerimeterCoords.hasOwnProperty('coordinates')) {
      let d: any = this.drawnPerimeterCoords;
      this.drawnPerimeterCoords = d.coordinates;
    }
    console.log(JSON.stringify(this.drawnPerimeterCoords));
    if (this.drawnPerimeterCoords.length == 0) return;
    this.preparePerimeterData();
    this.selectedPerimeter.user_id = this.selectedUser.id;
    this.geofencingService.insertNewPerimeter(this.selectedPerimeter);
    this.flagStatus = Status.USERSELECTED;
    this.setStatus();
    console.log('perimeter saved:');
    console.log(JSON.stringify(this.perimeterSelected));
    this.getAllPerimetersOfUser(this.selectedUser.id);
    this.perimetersDataSource.data = [];
    /* this.geofencingService.getAllPerimetersOfUser(this.selectedUser.id)
      .subscribe((res)=>{
        res.forEach(p => {            
            this.perimetersDataSource.data.push(p);
        })         
      }) */
    if (this.perimetersDataSource.data.length > 0)
      this.drawPerimeters(this.perimetersDataSource.data);
    else this.geomapService.resetMap();
  }

  saveToOtherUsers() {
    this.drawnPerimeterCoords =
      this.geomapService.getDrawnPerimeterCoordinates();
    if (this.drawnPerimeterCoords.length == 0) return;
    this.preparePerimeterData();
    this.selectedUsersData.forEach((su) => {
      this.selectedPerimeter.user_id = su.id;
      this.geofencingService.insertNewPerimeter(this.selectedPerimeter);
    });
    this.flagStatus = Status.USERSELECTED;
    this.setStatus();
    console.log('perimeters saved:');
    console.log(JSON.stringify(this.perimeterSelected));
    this.perimetersDataSource.data = [];
    /* this.geofencingService.getAllPerimetersOfUser(this.selectedUser.id)
      .subscribe((res)=>{
        res.forEach(p => {            
            this.perimetersDataSource.data.push(p);
        })         
      }) */
    if (this.perimetersDataSource.data.length > 0)
      this.drawPerimeters(this.perimetersDataSource.data);
    else this.geomapService.resetMap();
  }

  // open users form
  openUsersDialog(): void {
    const dialogRef = this.dialog.open(UsersComponent, {
      width: '80vw',
      data: {
        users: this.usersDataSource.data,
        users_selected: this.selectedUsersData,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.selectedUsersData = result;
      if (this.selectedUsersData.length > 0) this.saveToOtherUsers();
    });
  }

  onUserChange(u: any, event: any) {
    if (event.isUserInput) {
      this.userName = u.name;
      this.selectedUser = u;
      this.flagStatus = Status.USERSELECTED;
      this.setStatus();
      console.log(this.userName);
      this.geofencingService.getLastGeoPosition(u.id).subscribe((pos) => {
        if (pos.length > 0) { 
          /* let date = new Date(pos[0].value_timestamp);                  
          this.lastGeoPosition = pos[0].value[0].slice(0, -3)  + ', ' + pos[0].value[1].slice(0, -3) 
          + ' (' + this.datePipe.transform(date, 'dd/MM/yyyy hh:mm:ss') + ' UTC)';  */
          this.lastGeoPosition = pos[0].value[0].slice(0, -3)  + ', ' + pos[0].value[1].slice(0, -3) 
          + ' (' + pos[0].value_timestamp + ')'; 
        }
        else {
          this.lastGeoPosition = '-';
        }
      });
      this.getAllPerimetersOfUser(u.id);
    }
  }

  onPerimeterChange(p: any, event: any) {
    if (event.isUserInput) {
      //this.selectedPerimeter = p;
      //this.geomapService.selectPerimeter(p['name']);
      this.geomapService.rescaleViewPerimeter(p.coordinates.coordinates);
      //this.perimeterSelected.selected = true;
    }
  }

  openRemovePerimeterConfirmationDialog() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete this perimeter?';
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // do confirmation actions
        this.geofencingService.deletePerimeter(this.perimeterSelected.id);
        this.flagStatus = Status.USERSELECTED;
        this.setStatus();
        this.perimeterSelected.selected = false;
        console.log('perimeter removed:');
        console.log(JSON.stringify(this.perimeterSelected));
        this.perimetersDataSource.data = [];
        this.geofencingService
          .getAllPerimetersOfUser(this.selectedUser.id)
          .subscribe((res) => {
            res.forEach((p) => {
              this.perimetersDataSource.data.push(p);
            });
            this.geomapService.resetMap();
            if (this.perimetersDataSource.data.length > 0)
              this.drawPerimeters(this.perimetersDataSource.data);
          });
      }
    });
  }

  openRemovePerimeterForAllUsersConfirmationDialog() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete this perimeter for all users having it?';
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // do confirmation actions
        this.geofencingService.deletePerimetersByName(
          this.selectedPerimeter.name
        );
        this.flagStatus = Status.USERSELECTED;
        this.setStatus();
        this.perimeterSelected.selected = false;
        console.log('perimeters removed:');
        console.log(JSON.stringify(this.perimeterSelected));
        this.perimetersDataSource.data = [];
        this.geofencingService
          .getAllPerimetersOfUser(this.selectedUser.id)
          .subscribe((res) => {
            res.forEach((p) => {
              this.perimetersDataSource.data.push(p);
            });
          });
        this.geomapService.resetMap();
        if (this.perimetersDataSource.data.length > 0)
          this.drawPerimeters(this.perimetersDataSource.data);
      }
    });
  }

  // open Datadisplay form
  openDataDisplayDialog(): void {
    const dialogRef = this.dialog.open(DatadisplayComponent, {
      height: '90%',
      width: '80%',
      data: { perimeter: JSON.stringify(this.selectedPerimeter, null, '\t') },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //
    });
  }

  //Return to login page, clearing the status
  logOut() {
    location.assign('/');
  }
}
